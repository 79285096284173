






export default {
  name: 'Wallet',
  components: {
    Sponsor: () => import('@/views/Sponsor/components/Home.vue')
  }
};
